@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: transparent;
  cursor: pointer;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}

:hover::-webkit-scrollbar-thumb {
  border-radius: 7px;
  background-color: #b1b1b1;
}
::-webkit-scrollbar-thumb {
  border-radius: 7px;
  background-color: transparent;
}

html,
body {
}

#root,
.app {
  min-height: 100vh;
  height: auto !important;
}

.app {
  display: flex;
  flex-direction: column;
}

.login-logo {
  width: 80px;
  padding: 15px 12px 10px 15px;
}

.login-logo svg {
  fill: currentColor;
}

.app[data-theme='dark'] .logo {
  @apply border-gray-600;
}

.logo svg {
  fill: currentColor;
}
.brand-color {
  color: currentColor;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.app[data-theme='dark'] svg {
  stroke: #fff;
}

.app[data-theme='dark'] .arrow svg {
  stroke: #a6adba;
  fill: #a6adba;
}

.app[data-theme='dark'] .link-primary {
  @apply link-accent;
}

.app[data-theme='dark'] .offers-total,
.app[data-theme='dark'] .offers-top-panel,
.app[data-theme='dark'] .offer-item,
.app[data-theme='dark'] .offer,
.app[data-theme='dark'] .news-wrapper,
.app[data-theme='dark'] .theme-border,
.app[data-theme='dark'] .news-item {
  @apply border-gray-600;
}

.app .offers-total,
.app .offers-top-panel,
.app .offer-item,
.app .offer,
.app .news-wrapper,
.app .theme-border,
.app .news-item {
  @apply border;
}

.leads-list .table th:first-child {
  position: unset;
}
.page {
  height: 100%;
  width: 100%;

  margin-left: auto;
  margin-right: auto;
}
.login-page {
  min-height: 100vh;
}

.sub-text {
  color: rgba(50, 71, 92, 0.6);
}

.app[data-theme='dark'] .sub-text {
  color: rgb(166, 173, 186);
}

.notification {
  box-shadow: none;
  border-radius: 8px;
  padding: 15px 15px 15px 15px;
  opacity: 1;
}
.notification-error:before {
  content: '';
}
.notification-success:before {
  content: '';
}
.notification-error {
  background: #f87272;
}
.notification-success {
  background: #36d399;
}

.no-data-text {
  color: hsl(var(--bc) / 0.5);
}
.app .ce-block__content {
}

.color-base {
  color: hsla(var(--bc) / var(--tw-text-opacity, 1));
}

.color-base:hover {
  color: #fff;
}

.bg-base {
  background: #fff;
}
.bg-base:hover {
  @apply bg-primary;
}

.app[data-theme='dark'] .bg-base {
  @apply bg-base-100;
}

.app[data-theme='dark'] .bg-base:hover {
  @apply bg-primary;
}

.app[data-theme='dark'] .color-base {
  color: hsla(var(--bc) / var(--tw-text-opacity, 1));
}

.arrow-left svg {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
  cursor: pointer;
  fill: #000;
}
.arrow-left:hover svg {
  fill: #641ae6;
}

.offer-item svg {
  stroke: #fff;
}

.s-content {
  word-wrap: break-word;
}
.s-content h1 {
  @apply font-bold text-xl md:text-3xl mb-3 mt-3;
}
.s-content h1.news-title {
  @apply font-bold text-xl md:text-3xl mb-8 mt-3;
}

.s-content h2 {
  @apply font-bold  md:text-2xl text-lg mb-3 mt-3;
}
.s-content h3 {
  @apply font-bold md:text-xl mb-3 mt-3;
}
.s-content h4 {
  @apply font-bold md:text-lg mb-3 mt-3;
}
.s-content h4 {
  @apply font-bold text-base mb-3 mt-3;
}
.s-content h5 {
  @apply font-bold text-sm mb-3 mt-3;
}
.s-content h6 {
  @apply font-bold text-xs mb-3 mt-3;
}
.s-content table {
  @apply my-7;
  width: 100%;
  max-width: 100%;
}
.s-content table th,
.s-content table td {
  @apply p-3;
}

.s-content svg {
  stroke: #fff;
}

.s-content table {
  border-collapse: collapse;
  @apply border;
}
.s-content table td,
.s-content table th {
  @apply border;
}

.app[data-theme='dark'] .s-content table td,
.app[data-theme='dark'] .s-content table th,
.app[data-theme='dark'] .s-content table {
  @apply border-gray-600;
}

.s-content a {
  @apply link-primary;
}
.w-svg {
  @apply stroke-current flex-shrink-0 h-6 w-6;
}

.s-content .blockquote-4 {
  @apply my-7;
  position: relative;
  font-size: 16px;
  border: 2px solid #337ab7;
  border-radius: 20px;
  padding: 25px;
}
.s-content .blockquote-4::after {
  content: '';
  position: absolute;
  border: 2px solid #337ab7;
  border-radius: 0 50px 0 0;
  width: 40px;
  height: 40px;
  bottom: -40px;
  left: 50px;
  border-bottom: none;
  border-left: none;
  z-index: 2;
  box-sizing: border-box;
}
.s-content .blockquote-4::before {
  content: '';
  position: absolute;
  width: 80px;
  bottom: -3px;
  left: 50px;
  z-index: 1;
}
.s-content .blockquote-4 cite {
  position: absolute;
  bottom: -30px;
  left: 110px;
  font-size: 13px;
  font-weight: bold;
  color: #337ab7;
}
.s-content ul {
  @apply pl-2;
}
.s-content ul,
.s-content ol {
  @apply my-7;
}

.s-content ol {
  @apply pl-6;
  list-style-type: decimal;
}

.app .navbar .dropdown:focus .dropdown-content {
  visibility: hidden;
}
.app .navbar .dropdown:not(.dropdown-open) .dropdown-content {
  visibility: hidden;
}

.eye svg {
  opacity: 0.5;
}

.thumb-wrap {
  @apply m-5 ml-0;
  position: relative;
  padding-bottom: 56.25%; /* задаёт высоту контейнера для 16:9 (если 4:3 — поставьте 75%) */
  height: 0;
  overflow: hidden;
}
.thumb-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 70%;
  height: 70%;
  border-width: 0;
  outline-width: 0;
}

.g2-tooltip-marker {
  background: #570df8 !important;
}

.question-icon svg.icon-tabler-question-mark {
  stroke: #641ae6;
}

.app[data-theme='dark'] .question-icon svg.icon-tabler-question-mark {
  stroke: #25b2a6;
}

.icon-copy {
  stroke: #641ae6 !important;
}
.app[data-theme='dark'] .icon-copy {
  stroke: #1fb2a6 !important;
}

.question-icon {
  @apply border block w-[14px] h-[14px] rounded-full border-primary cursor-pointer;
}

.app[data-theme='dark'] .question-icon {
  @apply border-accent;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.mob-collapse {
  border-left: none;
  border-right: none;
  border-top: none;
}

.mob-collapse.collapse-close .collapse-content {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.mob-collapse.no-arrow .collapse-title::after {
  content: none;
}

.mob-collapse.collapse-close .collapse-title::after {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(45deg) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.react-datepicker-wrapper {
  width: 100%;
}

@media (max-width: 1024px) {
  .thumb-wrap {
    @apply mr-0;
  }
  .thumb-wrap iframe {
    width: 100%;
    height: 100%;
  }
}
.terms h3 {
  @apply font-medium my-3 text-xl;
}
.terms p {
  @apply py-2;
}
