.expand-icon {
  @apply cursor-pointer relative;
}

.expand-icon .counter {
  @apply absolute rounded-full w-2 h-2;
  top: 0px;
  right: 0px;
}
.expand-icon svg {
  margin: auto;
  display: inline;
  stroke: hsl(var(--p) / var(--tw-bg-opacity));
}

.app[data-theme="dark"] .expand-icon svg {
  stroke: #25b2a6;
}

textarea.note {
  resize: none;
}

.row-notes .notes {
  @apply input-group;
}

.row-notes .notes .select-priority {
  @apply max-w-xs;
}
