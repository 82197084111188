.note {
  @apply flex items-center gap-1 w-fit;
  color: black;
}
.remove {
  cursor: pointer;
  opacity: 0;
}
.note:hover .remove {
  opacity: 1;
}
.app[data-theme="dark"] .remove svg {
  stroke: #f87272;
}
